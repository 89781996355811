<template>

  <v-container>
    <row-header :msg="msgRowHeader" :action="msgRowHeader.actionName"></row-header>

    <trx-search-form-componen
        v-if="$store.getters.isLoggedIn"
        :trx_model="trx_model"
        :categories="categories"
        :agents="agents"
        :lockets="lockets"
        :fee_kilat="fee_kilat"
        :fee_agent="fee_agent"
        :tot_trx="tot_trx"
        :tot_amt="tot_amt"
        :status_list="status_list"
        from="deposit"
    />

    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dense
            ></v-text-field>
          </v-card-title>
          <v-data-table
            item-key="name"
            class="elevation-1"
            loading
            loading-text="Loading... Please wait"
            v-show="loading"
            dense
          ></v-data-table>
          <v-data-table
            dense
            :headers="headers"
            :items="transactions"
            :footer-props="rowsPerPageItems"
            :items-per-page="pagination"
            item-key="no"
            :search="search"
            v-show="!loading"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2 green--text"
                @click="viewItem(item)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <dialog-confirm></dialog-confirm>
  </v-container>

</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import DialogConfirm from "../../../components/content/DialogConfirm";
import { GetRequest } from '../../../store/services/users';
import { TrxData, StatusList } from '../../../store/services/TrxModel';
import TrxSearchFormComponen from '../../../components/page/trx/TrxSearchFormComponen'

export default {
  components: {
    TrxSearchFormComponen,
    RowHeader,
    DialogConfirm,
  },
  data: () => ({
    search: null,
    rowsPerPageItems: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000]
    },
    pagination: 20,
    loading: true,
    msgRowHeader: {newUser: 'Create New', newMsg: 'Deposit History', actionName: 'DepositNew'},
    transactions: [],
    headers: [
      { text: 'No', align: 'end', value: 'no', width: '75px'},
      { text: 'tx_id', value: 'tx_id', width: '200px' },
      { text: 'sn', value: 'sn', width: '200px' },
      { text: 'created_by', value: 'created_by', width: '200px' },
      { text: 'merchant', value: 'merchant_code', width: '150px' },
      { text: 'status', value: 'status', width: '100px' },
      { text: 'amount', value: 'amount_str', width: '200px', align: 'end' },
      { text: 'first_balance', value: 'first_balance', align: 'end', width: '130px' },
      { text: 'last_balance', value: 'last_balance', align: 'end', width: '130px' },
      { text: 'action', value: 'action', width: '100px' },
    ],
    dataSelected: {},
    trx_model: new TrxData(),
    categories: [],
    agents: [],
    lockets: [],
    status_list: new StatusList(),
    tot_trx: 0,
    fee_agent: 0,
    fee_kilat: 0,
    tot_amt: 0,
    isAdmin: false,
  }),
  async mounted() {
    console.log('view page admin deposit');
    if (this.$store.getters.isLoggedIn) {

      if (this.$store.getters.currentUser.role.level === 1) {
        this.isAdmin = true;
        this.msgRowHeader = {newUser: '', newMsg: 'Deposit List', actionName: 'non'};
      }

      this.trx_model.from_date = this.$moment().format('YYYY-MM-DD');
      this.trx_model.to_date = this.$moment().format('YYYY-MM-DD');
      await this.getTrxData();
      this.categories = await this.getCatData();
      if (this.$store.getters.currentUser?.role?.level === 1) {
        this.agents = await this.getAgentData();
        this.lockets = [];
      } else {
        this.lockets = await this.getLocketData('agent');
      }

      this.action.$on('change-agent-action', async (val) => {
        await this.getTrxData();
        if (val) {
          await this.categoryChangeHandler(val);
        } else {
          this.lockets = [];
        }
      });
      this.action.$on('change-date-action', async () => {
        await this.getTrxData();
      });
    }
  },
  beforeDestroy() {
    this.action.$off('change-agent-action');
    this.action.$off('change-date-action');
    // this.action.$off('open-dialog-conf-user');
  },
  methods : {
    async getCatData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('categories');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getAgentData() {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('users/agent');
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getLocketData(id) {
      await this.$store.dispatch('req_data');
      this.overlay = await this.$store.getters.isLoading;
      try{
        let response = await GetRequest('users/locket/' + id);
        await this.$store.dispatch('req_success');
        this.overlay = await this.$store.getters.isLoading;
        return response.data;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = await this.$store.getters.isLoading;
        alert('failed to get data : ' + err.message);
        return [];
      }
    },
    async getTrxData() {
      await this.$store.dispatch('req_data');
      this.loading = this.$store.getters.isLoading;
      this.trx_model.type = '00';
      try{
        const queryParams = new URLSearchParams(this.trx_model).toString();
        let response = await GetRequest('transactions?' + queryParams);
        let fee_agent = 0;
        let fee_kilat = 0;
        let tot_amt = 0;
        this.transactions = response.data;
        this.transactions.forEach((val, i) => {
          val.no = i+1;
          fee_agent += val.fee_merchant;
          fee_kilat += val.fee_kilat;
          tot_amt += val.amount;
          // val.active = this.mappingStatus(val.active);
          val.amount_str = this.$options.filters.amountFormatNoPrefix(val.amount);
          val.first_balance = this.$options.filters.amountFormatNoPrefix(val.first_balance);
          val.last_balance = this.$options.filters.amountFormatNoPrefix(val.last_balance);
          val.fee_kilat = this.$options.filters.amountFormatNoPrefix(val.fee_kilat);
          val.fee_merchant = this.$options.filters.amountFormatNoPrefix(val.fee_merchant);
          // val.category = val.category.name;
          // val.provider_prod_code = val.provider_prod_code.name;
          val.status = this.mappingStatus(val.status);
          val.created_at = val.created_at ? this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss') : '-';
        })
        this.fee_agent = fee_agent;
        this.fee_kilat = fee_kilat;
        this.tot_amt = tot_amt;
        this.tot_trx = this.transactions.length;
        await this.$store.dispatch('req_success');
        this.loading = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.loading = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async categoryChangeHandler(val) {
      this.lockets = await this.getLocketData(val);
    },
    mappingStatus(status) {
      let res;
      if (Number(status) === 0) {
        res = 'Success';
      } else if (Number(status) === 1) {
        res = 'Advice';
      } else if (Number(status) === 2) {
        res = 'Cancel';
      } else if (Number(status) === 3) {
        res = 'Inquiry';
      } else if (Number(status) === 4) {
        res = 'Success with settlement';
      } else if (Number(status) === 5) {
        res = 'waiting callback';
      }

      return res;
    },
    viewItem(data) {
      console.log(data)
      this.$router.push({name: 'DepositEdit', params: data});
    },
  }
}
</script>

<style scoped>

</style>
